import ProductCardWholesale from "@src/components/ProductCardWholesale"
import getSiteUrl from "@src/utilities/get-site-url"

import type { BundleProductCard } from "@src/entities/models/bundle-product-card"

export interface ProductGridWholesaleProps {
  content: {
    container_width?: {
      containerWidth?: string
    }
    section_color?: string
    headline?: string
    preheadline?: string
    product_cards?: BundleProductCard[]
    _uid?: string
  }
}
export default function ProductGridWholesale({
  content,
}: ProductGridWholesaleProps) {
  return (
    <div
      className={`container-${
        content.container_width?.containerWidth ?? "website"
      }`}
    >
      <div className="w-full">
        {content.preheadline && content.preheadline.length > 0 && (
          <p
            className="italic font-serif mb-2 text-lg text-center"
            dangerouslySetInnerHTML={{ __html: content.preheadline }}
          />
        )}
        {content.headline && content.headline.length > 0 && (
          <h2
            className="mb-8 font-extrabold text-center text-lg md:text-2xl uppercase"
            dangerouslySetInnerHTML={{ __html: content.headline }}
            style={{
              ...(content?.section_color && { color: content.section_color }),
            }}
          />
        )}
        <div className="mb-4 md:mb-8 md:grid md:place-content-center md:gap-8 md:place-content-center md:grid-cols-2 lg:mb-16 lg:grid-cols-3 xl:grid-cols-4">
          {content.product_cards?.map((card, idx) => {
            if (card.component !== "wholesale_product_card") {
              return null
            }
            const minQty = Number(card.min_quantity ?? "1")
            return (
              <ProductCardWholesale
                key={`grid-${content._uid}-${idx}`}
                minQuantity={minQty}
                product={card.product}
                sectionColor={content.section_color ?? null}
                url={getSiteUrl(card?.product_page?.cached_url ?? "")}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
