import React from "react"
import { HiArrowTopRightOnSquare } from "react-icons/hi2"

import { formatCurrency } from "@src/utilities/format-numbers"

import type { BundleProductCard } from "@src/entities/models/bundle-product-card"

export interface ProductCardWholesaleProps {
  minQuantity: number
  product?: BundleProductCard["product"]
  sectionColor: string | null
  url?: string
}
export default function ProductCardWholesale({
  minQuantity,
  product,
  sectionColor,
  url,
}: ProductCardWholesaleProps) {
  if (!product) {
    return null
  }

  const description = product?.description ?? "Description not set"

  return (
    <div
      className={[
        "group flex flex-col justify-start transition-colors border border-transparent rounded-sm hover:border-gray-300",
      ].join(" ")}
      data-component="product-card-wholesale"
    >
      <a
        href={url}
        aria-label={product?.name ?? ""}
        className="bg-center bg-cover block relative before:content-[''] before:block before:pt-[100%] lg:w-4/5"
        style={{
          backgroundImage: `url(${
            product?.thumbnailUrl || "/images/placeholder.jpeg"
          })`,
        }}
        target={"_blank"}
      />
      <div className="px-4 md:flex md:flex-col grow p-4">
        <h3>
          <a
            className="font-extrabold text-2xl mb-1 uppercase"
            dangerouslySetInnerHTML={{
              __html: product?.name ?? "Name not set",
            }}
            href={url}
            target="_blank"
          />
        </h3>
        <p
          className="font-serif text-base italic tracking-wide m2-4"
          dangerouslySetInnerHTML={{
            __html: `${description} (Qty: ${minQuantity})`,
          }}
        />
        <div
          className="font-medium my-2 px-2 pt-2 pb-1 text-sm"
          style={{
            ...(sectionColor && { backgroundColor: sectionColor }),
          }}
        >
          MSRP: {formatCurrency(product.priceRetail)}ea.
        </div>
        <a
          href={url}
          className="flex justify-between align-center"
          target={"_blank"}
        >
          <span className="font-serif">Full product details</span>
          <HiArrowTopRightOnSquare className="inline-block" />
        </a>
      </div>
    </div>
  )
}
